
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Simulation from "~/components/simulation";
import SimulationResultSingle from "~/components/result/simulationResultSingle";
import OptimizationOpportunityLinesView from "~/components/result/OptimizationOpportunityLinesView.vue";

@Component({
  components: { OptimizationOpportunityLinesView }
})
export default class OptimizationOpportunitySummary extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  private cachedValues = new Map<string, number>();
  @Watch("simulation", { immediate: true, deep: true })
  onSimulationChanged() {
    this.cachedValues.clear();
  }

  get isForDoubleReturn() {
    return this.simulation.result!.double;
  }

  get retirementSavingsLines() {
    return [
      { key: "line_1", label: "result.max_tax_exemption" },
      {
        key: "line_2",
        label: "result.formule_30",
        value: this.maximumTaxExemptAangever30,
        partnerValue: this.maximumTaxExemptPartner30,
        indent: true,
        tooltip: "result.formule_tooltip",
      },
      {
        key: "line_3",
        label: "result.formule_25",
        value: this.maximumTaxExemptAangever25,
        partnerValue: this.maximumTaxExemptPartner25,
        indent: true,
        tooltip: "result.formule_tooltip",
      },
      {
        key: "line_4",
        label: "result.current_tax_exemption",
        value: this.getValue("1361"),
        partnerValue: this.getValue("2361"),
      },
      {
        key: "line_5",
        label: "result.formule_30",
        paddingTop: true,
      },
      {
        key: "line_6",
        label: "result.additional_deposit",
        value: this.stillAvailableTaxExemptionDeclarant30,
        partnerValue: this.stillAvailableTaxExemptionPartner30,
        indent: true,
      },
      {
        key: "line_7",
        label: "result.extra_tax_saving",
        value: this.possibleExtraSavingsDeclarantFor30,
        partnerValue: this.possibleExtraSavingsPartnerFor30,
        indent: true,
        tooltip: "result.extra_tax_saving_tooltip",
      },
      {
        key: "line_8",
        label: "result.percentage_of_savings",
        value: this.percentageOfSavingsDeclarant30,
        partnerValue: this.percentageOfSavingsPartner30,
        percentage: true,
      },
      {
        key: "line_9",
        label: "result.formule_25",
        paddingTop: true,
      },
      {
        key: "line_10",
        label: "result.additional_deposit",
        value: this.stillAvailableTaxExemptionDeclarant25,
        partnerValue: this.stillAvailableTaxExemptionPartner25,
        indent: true,
      },
      {
        key: "line_11",
        label: "result.extra_tax_saving",
        value: this.possibleExtraSavingsDeclarantFor25,
        partnerValue: this.possibleExtraSavingsPartnerFor25,
        indent: true,
        tooltip: "result.extra_tax_saving_tooltip",
      },
      {
        key: "line_12",
        label: "result.percentage_of_savings",
        value: this.percentageOfSavingsDeclarant25,
        partnerValue: this.percentageOfSavingsPartner25,
        percentage: true,
      },
    ];
  }

  getValue(code: string): number {
    if (!this.cachedValues.has(code)) {
      const value = this.simulation?.input?.codeItems?.get(code)?.value ?? 0.0;
      this.cachedValues.set(code, value);
    }
    return this.cachedValues.get(code)!;
  }

  get municipalTaxRate() {
    return this.getValue("1061");
  }

  get stillAvailableTaxExemptionDeclarant30() {
    return this.max(0.0, this.maximumTaxExemptAangever30 - this.getValue("1361"));
  }

  get stillAvailableTaxExemptionPartner30(): number {
    return this.max(0.0, this.maximumTaxExemptPartner30 - this.getValue("2361"));
  }

  get percentageOfSavingsDeclarant30() {
    return this.computePercentage(this.stillAvailableTaxExemptionDeclarant30, this.possibleExtraSavingsDeclarantFor30);
  }

  get percentageOfSavingsPartner30() {
    return this.computePercentage(this.stillAvailableTaxExemptionPartner30, this.possibleExtraSavingsPartnerFor30);
  }

  get possibleExtraSavingsDeclarantFor30() {
    return this.computeSavings("1361", this.stillAvailableTaxExemptionDeclarant30, this.maximumTaxExemptAangever30);
  }

  get possibleExtraSavingsPartnerFor30() {
    return this.computeSavings("2361", this.stillAvailableTaxExemptionPartner30, this.maximumTaxExemptPartner30);
  }

  computePercentage(stillAvailable: number, possibleExtraSavings: number) {
    if (stillAvailable === 0) {
      return 0;
    } else {
      return (possibleExtraSavings / stillAvailable) * 100;
    }
  }

  computeSavings(code: string, stillAvailable: number, maximumAvailable: number, isFor30 = true): number {
    // Define savings percentages based on the isFor30 flag
    const currentSavingsPercentage = isFor30 ? 0.25 : 0.3;
    const newSavingsPercentage = isFor30 ? 0.3 : 0.25;

    // Retrieve current value based on the code
    const currentValue = this.getValue(code);

    // Calculate tax-adjusted multiplier
    const taxAdjustedMultiplier = 1 + this.municipalTaxRate / 100;

    // Calculate the savings for the current deposit if applicable
    if (currentValue >= this.maxValueFor30) {
      const savingsForCurrentDeposit = currentValue * currentSavingsPercentage * taxAdjustedMultiplier;
      return maximumAvailable * newSavingsPercentage * taxAdjustedMultiplier - savingsForCurrentDeposit;
    }

    // Return the new potential savings for still available deposits
    return stillAvailable * newSavingsPercentage * taxAdjustedMultiplier;
  }

  get stillAvailableTaxExemptionDeclarant25() {
    return this.max(0.0, this.maximumTaxExemptAangever25 - this.getValue("1361"));
  }

  get stillAvailableTaxExemptionPartner25() {
    return this.max(0.0, this.maximumTaxExemptPartner25 - this.getValue("2361"));
  }

  get percentageOfSavingsDeclarant25() {
    return this.computePercentage(this.stillAvailableTaxExemptionDeclarant25, this.possibleExtraSavingsDeclarantFor25);
  }

  get percentageOfSavingsPartner25() {
    return this.computePercentage(this.stillAvailableTaxExemptionPartner25, this.possibleExtraSavingsPartnerFor25);
  }

  get possibleExtraSavingsDeclarantFor25() {
    return this.computeSavings(
      "1361",
      this.stillAvailableTaxExemptionDeclarant25,
      this.maximumTaxExemptAangever25,
      false
    );
  }

  get possibleExtraSavingsPartnerFor25() {
    return this.computeSavings("2361", this.stillAvailableTaxExemptionPartner25, this.maximumTaxExemptPartner25, false);
  }

  get maximumTaxExemptAangever30() {
    return this.min(
      this.maxValueFor30,
      (this.getSumFederalAndRegionalTax(this.simulation?.result?.primaryResult) / 30) * 100
    );
  }

  get maximumTaxExemptPartner30() {
    return this.min(
      this.maxValueFor30,
      (this.getSumFederalAndRegionalTax(this.simulation?.result?.secondaryResult) / 30) * 100
    );
  }

  get maximumTaxExemptAangever25() {
    return this.min(
      this.maxValueFor25,
      this.max(
        0.0,
        (this.getSumFederalAndRegionalTax(this.simulation?.result?.primaryResult) / 25) * 100 - this.maxValueFor30
      )
    );
  }

  get maximumTaxExemptPartner25() {
    return this.min(
      this.maxValueFor25,
      this.max(
        0.0,
        (this.getSumFederalAndRegionalTax(this.simulation?.result?.secondaryResult) / 25) * 100 - this.maxValueFor30
      )
    );
  }

  private getSumFederalAndRegionalTax(result: SimulationResultSingle | null | undefined): number {
    return this.max(0.0, (result?.federalTaxBalance ?? 0.0) + (result?.regionalTaxBalance ?? 0.0));
  }

  get maxValueFor25(): number {
    return this.getMaxValue("max_value");
  }

  get maxValueFor30(): number {
    return this.getMaxValue("max_value_30");
  }

  getStaticInfoRetirementSavingsPayments() {
    const retirementSavings = this.staticAmounts.tax_reductions.retirement_savings_payments;
    const thirtyPercentMax = retirementSavings['30_percent_max'];
    const twentyFivePercentMax = retirementSavings['25_percent_max'];
    return { thirtyPercentMax, twentyFivePercentMax };
  }

  get staticAmounts(): any {
    return require(`@/assets/${this.simulation.input.taxYear}/tax_numbers.json`);
  }

  private getMaxValue(key: string): number {
    if (!this.cachedValues.has(key)) {
      const { thirtyPercentMax, twentyFivePercentMax } = this.getStaticInfoRetirementSavingsPayments();

      this.cachedValues.set('max_value', isNaN(twentyFivePercentMax) ? 0 : twentyFivePercentMax);
      this.cachedValues.set('max_value_30', isNaN(thirtyPercentMax) ? 0 : thirtyPercentMax);
    }

    if (!this.cachedValues.has(key)) {
      throw new Error(`Requested key "${key}" not found in cached values`);
    }

    return this.cachedValues.get(key)!;
  }

  min(a: number, b: number) {
    return Math.min(a, b);
  }

  max(a: number, b: number) {
    return Math.max(a, b);
  }
}
